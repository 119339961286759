import { default as aboutv3Wuob7z2aMeta } from "/app/src/pages/about.vue?macro=true";
import { default as cartvp2OdqOHLlMeta } from "/app/src/pages/cart.vue?macro=true";
import { default as _91id_93cZS0hvaK79Meta } from "/app/src/pages/catalog/[id].vue?macro=true";
import { default as indexjK4xN00swSMeta } from "/app/src/pages/catalog/index.vue?macro=true";
import { default as checkout_45mainuJJr7hKAsOMeta } from "/app/src/pages/checkout-main.vue?macro=true";
import { default as contactseKkCzFBATkMeta } from "/app/src/pages/contacts.vue?macro=true";
import { default as deliveryRKiaBYhjD2Meta } from "/app/src/pages/delivery.vue?macro=true";
import { default as error404cpc9Tmpv0SMeta } from "/app/src/pages/error404.vue?macro=true";
import { default as error500MoB55OQZtcMeta } from "/app/src/pages/error500.vue?macro=true";
import { default as favoritesPKQSU2wep6Meta } from "/app/src/pages/favorites.vue?macro=true";
import { default as gift_45ideas6SHDSe8Y5nMeta } from "/app/src/pages/gift-ideas.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as informationnyYHgF6O3dMeta } from "/app/src/pages/information.vue?macro=true";
import { default as legal_45entitiesGKYT2MXdtNMeta } from "/app/src/pages/legal-entities.vue?macro=true";
import { default as indexZ4n3ObLlaBMeta } from "/app/src/pages/loans/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as NotFoundCnqetTdwICMeta } from "/app/src/pages/NotFound.vue?macro=true";
import { default as orderzpDezzq0ZeMeta } from "/app/src/pages/order.vue?macro=true";
import { default as popular80obkvBUMVMeta } from "/app/src/pages/popular.vue?macro=true";
import { default as _91id_9329Bb057sglMeta } from "/app/src/pages/product/[id].vue?macro=true";
import { default as search5UN5dWklNeMeta } from "/app/src/pages/search.vue?macro=true";
import { default as seasonal0fDPi11SnDMeta } from "/app/src/pages/seasonal.vue?macro=true";
import { default as servicesclv3MtwTQZMeta } from "/app/src/pages/services.vue?macro=true";
import { default as client_45organization_45_91id_93wyuFzxz00OMeta } from "/app/src/pages/user/client-organization-[id].vue?macro=true";
import { default as indexAXkeIboLlqMeta } from "/app/src/pages/user/index.vue?macro=true";
import { default as _91id_93Z2JIui2XcUMeta } from "/app/src/pages/user/orders/[id].vue?macro=true";
import { default as indexySMBgLruGXMeta } from "/app/src/pages/user/orders/index.vue?macro=true";
import { default as _91id_9300HSo72PDJMeta } from "/app/src/pages/user/organizations/[id].vue?macro=true";
import { default as indexMVrVIVMDN7Meta } from "/app/src/pages/user/organizations/index.vue?macro=true";
import { default as vacanciesJsbnKZNDhZMeta } from "/app/src/pages/vacancies.vue?macro=true";
export default [
  {
    name: aboutv3Wuob7z2aMeta?.name ?? "about",
    path: aboutv3Wuob7z2aMeta?.path ?? "/about",
    meta: aboutv3Wuob7z2aMeta || {},
    alias: aboutv3Wuob7z2aMeta?.alias || [],
    redirect: aboutv3Wuob7z2aMeta?.redirect,
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: cartvp2OdqOHLlMeta?.name ?? "cart",
    path: cartvp2OdqOHLlMeta?.path ?? "/cart",
    meta: cartvp2OdqOHLlMeta || {},
    alias: cartvp2OdqOHLlMeta?.alias || [],
    redirect: cartvp2OdqOHLlMeta?.redirect,
    component: () => import("/app/src/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cZS0hvaK79Meta?.name ?? "catalog-id",
    path: _91id_93cZS0hvaK79Meta?.path ?? "/catalog/:id()",
    meta: _91id_93cZS0hvaK79Meta || {},
    alias: _91id_93cZS0hvaK79Meta?.alias || [],
    redirect: _91id_93cZS0hvaK79Meta?.redirect,
    component: () => import("/app/src/pages/catalog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjK4xN00swSMeta?.name ?? "catalog",
    path: indexjK4xN00swSMeta?.path ?? "/catalog",
    meta: indexjK4xN00swSMeta || {},
    alias: indexjK4xN00swSMeta?.alias || [],
    redirect: indexjK4xN00swSMeta?.redirect,
    component: () => import("/app/src/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: checkout_45mainuJJr7hKAsOMeta?.name ?? "checkout-main",
    path: checkout_45mainuJJr7hKAsOMeta?.path ?? "/checkout-main",
    meta: checkout_45mainuJJr7hKAsOMeta || {},
    alias: checkout_45mainuJJr7hKAsOMeta?.alias || [],
    redirect: checkout_45mainuJJr7hKAsOMeta?.redirect,
    component: () => import("/app/src/pages/checkout-main.vue").then(m => m.default || m)
  },
  {
    name: contactseKkCzFBATkMeta?.name ?? "contacts",
    path: contactseKkCzFBATkMeta?.path ?? "/contacts",
    meta: contactseKkCzFBATkMeta || {},
    alias: contactseKkCzFBATkMeta?.alias || [],
    redirect: contactseKkCzFBATkMeta?.redirect,
    component: () => import("/app/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: deliveryRKiaBYhjD2Meta?.name ?? "delivery",
    path: deliveryRKiaBYhjD2Meta?.path ?? "/delivery",
    meta: deliveryRKiaBYhjD2Meta || {},
    alias: deliveryRKiaBYhjD2Meta?.alias || [],
    redirect: deliveryRKiaBYhjD2Meta?.redirect,
    component: () => import("/app/src/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: error404cpc9Tmpv0SMeta?.name ?? "error404",
    path: error404cpc9Tmpv0SMeta?.path ?? "/error404",
    meta: error404cpc9Tmpv0SMeta || {},
    alias: error404cpc9Tmpv0SMeta?.alias || [],
    redirect: error404cpc9Tmpv0SMeta?.redirect,
    component: () => import("/app/src/pages/error404.vue").then(m => m.default || m)
  },
  {
    name: error500MoB55OQZtcMeta?.name ?? "error500",
    path: error500MoB55OQZtcMeta?.path ?? "/error500",
    meta: error500MoB55OQZtcMeta || {},
    alias: error500MoB55OQZtcMeta?.alias || [],
    redirect: error500MoB55OQZtcMeta?.redirect,
    component: () => import("/app/src/pages/error500.vue").then(m => m.default || m)
  },
  {
    name: favoritesPKQSU2wep6Meta?.name ?? "favorites",
    path: favoritesPKQSU2wep6Meta?.path ?? "/favorites",
    meta: favoritesPKQSU2wep6Meta || {},
    alias: favoritesPKQSU2wep6Meta?.alias || [],
    redirect: favoritesPKQSU2wep6Meta?.redirect,
    component: () => import("/app/src/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: gift_45ideas6SHDSe8Y5nMeta?.name ?? "gift-ideas",
    path: gift_45ideas6SHDSe8Y5nMeta?.path ?? "/gift-ideas",
    meta: gift_45ideas6SHDSe8Y5nMeta || {},
    alias: gift_45ideas6SHDSe8Y5nMeta?.alias || [],
    redirect: gift_45ideas6SHDSe8Y5nMeta?.redirect,
    component: () => import("/app/src/pages/gift-ideas.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: informationnyYHgF6O3dMeta?.name ?? "information",
    path: informationnyYHgF6O3dMeta?.path ?? "/information",
    meta: informationnyYHgF6O3dMeta || {},
    alias: informationnyYHgF6O3dMeta?.alias || [],
    redirect: informationnyYHgF6O3dMeta?.redirect,
    component: () => import("/app/src/pages/information.vue").then(m => m.default || m)
  },
  {
    name: legal_45entitiesGKYT2MXdtNMeta?.name ?? "legal-entities",
    path: legal_45entitiesGKYT2MXdtNMeta?.path ?? "/legal-entities",
    meta: legal_45entitiesGKYT2MXdtNMeta || {},
    alias: legal_45entitiesGKYT2MXdtNMeta?.alias || [],
    redirect: legal_45entitiesGKYT2MXdtNMeta?.redirect,
    component: () => import("/app/src/pages/legal-entities.vue").then(m => m.default || m)
  },
  {
    name: indexZ4n3ObLlaBMeta?.name ?? "loans",
    path: indexZ4n3ObLlaBMeta?.path ?? "/loans",
    meta: indexZ4n3ObLlaBMeta || {},
    alias: indexZ4n3ObLlaBMeta?.alias || [],
    redirect: indexZ4n3ObLlaBMeta?.redirect,
    component: () => import("/app/src/pages/loans/index.vue").then(m => m.default || m)
  },
  {
    name: login3hUYZUGxmUMeta?.name ?? "login",
    path: login3hUYZUGxmUMeta?.path ?? "/login",
    meta: login3hUYZUGxmUMeta || {},
    alias: login3hUYZUGxmUMeta?.alias || [],
    redirect: login3hUYZUGxmUMeta?.redirect,
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: NotFoundCnqetTdwICMeta?.name ?? "NotFound",
    path: NotFoundCnqetTdwICMeta?.path ?? "/NotFound",
    meta: NotFoundCnqetTdwICMeta || {},
    alias: NotFoundCnqetTdwICMeta?.alias || [],
    redirect: NotFoundCnqetTdwICMeta?.redirect,
    component: () => import("/app/src/pages/NotFound.vue").then(m => m.default || m)
  },
  {
    name: orderzpDezzq0ZeMeta?.name ?? "order",
    path: orderzpDezzq0ZeMeta?.path ?? "/order",
    meta: orderzpDezzq0ZeMeta || {},
    alias: orderzpDezzq0ZeMeta?.alias || [],
    redirect: orderzpDezzq0ZeMeta?.redirect,
    component: () => import("/app/src/pages/order.vue").then(m => m.default || m)
  },
  {
    name: popular80obkvBUMVMeta?.name ?? "popular",
    path: popular80obkvBUMVMeta?.path ?? "/popular",
    meta: popular80obkvBUMVMeta || {},
    alias: popular80obkvBUMVMeta?.alias || [],
    redirect: popular80obkvBUMVMeta?.redirect,
    component: () => import("/app/src/pages/popular.vue").then(m => m.default || m)
  },
  {
    name: _91id_9329Bb057sglMeta?.name ?? "product-id",
    path: _91id_9329Bb057sglMeta?.path ?? "/product/:id()",
    meta: _91id_9329Bb057sglMeta || {},
    alias: _91id_9329Bb057sglMeta?.alias || [],
    redirect: _91id_9329Bb057sglMeta?.redirect,
    component: () => import("/app/src/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: search5UN5dWklNeMeta?.name ?? "search",
    path: search5UN5dWklNeMeta?.path ?? "/search",
    meta: search5UN5dWklNeMeta || {},
    alias: search5UN5dWklNeMeta?.alias || [],
    redirect: search5UN5dWklNeMeta?.redirect,
    component: () => import("/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: seasonal0fDPi11SnDMeta?.name ?? "seasonal",
    path: seasonal0fDPi11SnDMeta?.path ?? "/seasonal",
    meta: seasonal0fDPi11SnDMeta || {},
    alias: seasonal0fDPi11SnDMeta?.alias || [],
    redirect: seasonal0fDPi11SnDMeta?.redirect,
    component: () => import("/app/src/pages/seasonal.vue").then(m => m.default || m)
  },
  {
    name: servicesclv3MtwTQZMeta?.name ?? "services",
    path: servicesclv3MtwTQZMeta?.path ?? "/services",
    meta: servicesclv3MtwTQZMeta || {},
    alias: servicesclv3MtwTQZMeta?.alias || [],
    redirect: servicesclv3MtwTQZMeta?.redirect,
    component: () => import("/app/src/pages/services.vue").then(m => m.default || m)
  },
  {
    name: client_45organization_45_91id_93wyuFzxz00OMeta?.name ?? "user-client-organization-id",
    path: client_45organization_45_91id_93wyuFzxz00OMeta?.path ?? "/user/client-organization-:id()",
    meta: client_45organization_45_91id_93wyuFzxz00OMeta || {},
    alias: client_45organization_45_91id_93wyuFzxz00OMeta?.alias || [],
    redirect: client_45organization_45_91id_93wyuFzxz00OMeta?.redirect,
    component: () => import("/app/src/pages/user/client-organization-[id].vue").then(m => m.default || m)
  },
  {
    name: indexAXkeIboLlqMeta?.name ?? "user",
    path: indexAXkeIboLlqMeta?.path ?? "/user",
    meta: indexAXkeIboLlqMeta || {},
    alias: indexAXkeIboLlqMeta?.alias || [],
    redirect: indexAXkeIboLlqMeta?.redirect,
    component: () => import("/app/src/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z2JIui2XcUMeta?.name ?? "user-orders-id",
    path: _91id_93Z2JIui2XcUMeta?.path ?? "/user/orders/:id()",
    meta: _91id_93Z2JIui2XcUMeta || {},
    alias: _91id_93Z2JIui2XcUMeta?.alias || [],
    redirect: _91id_93Z2JIui2XcUMeta?.redirect,
    component: () => import("/app/src/pages/user/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexySMBgLruGXMeta?.name ?? "user-orders",
    path: indexySMBgLruGXMeta?.path ?? "/user/orders",
    meta: indexySMBgLruGXMeta || {},
    alias: indexySMBgLruGXMeta?.alias || [],
    redirect: indexySMBgLruGXMeta?.redirect,
    component: () => import("/app/src/pages/user/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9300HSo72PDJMeta?.name ?? "user-organizations-id",
    path: _91id_9300HSo72PDJMeta?.path ?? "/user/organizations/:id()",
    meta: _91id_9300HSo72PDJMeta || {},
    alias: _91id_9300HSo72PDJMeta?.alias || [],
    redirect: _91id_9300HSo72PDJMeta?.redirect,
    component: () => import("/app/src/pages/user/organizations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMVrVIVMDN7Meta?.name ?? "user-organizations",
    path: indexMVrVIVMDN7Meta?.path ?? "/user/organizations",
    meta: indexMVrVIVMDN7Meta || {},
    alias: indexMVrVIVMDN7Meta?.alias || [],
    redirect: indexMVrVIVMDN7Meta?.redirect,
    component: () => import("/app/src/pages/user/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: vacanciesJsbnKZNDhZMeta?.name ?? "vacancies",
    path: vacanciesJsbnKZNDhZMeta?.path ?? "/vacancies",
    meta: vacanciesJsbnKZNDhZMeta || {},
    alias: vacanciesJsbnKZNDhZMeta?.alias || [],
    redirect: vacanciesJsbnKZNDhZMeta?.redirect,
    component: () => import("/app/src/pages/vacancies.vue").then(m => m.default || m)
  }
]